// multiselect

.multiselect {
    .selected-list {
        .c-btn {
            display: flex;
            align-items: center;
            min-height: 34px !important;
            padding: 0px 10px !important;
            border-color: #dadfe6 !important;
            font-size: 13px;
            .c-token {
                font-size: 13px;
                margin-bottom: 2px;
                background: var(--colorCommon);
                padding: 3px 25px 3px 8px !important;
                .c-remove {
                    width: 7px !important;
                }
            }
        }
        .c-angle-down,
        .c-angle-up {
            width: 10px !important;
            height: inherit !important;
        }
    }
    .dropdown-list.tagToBody {
        position: absolute !important;
    }
    .dropdown-list {
        top: 0 !important;
        left: 0 !important;
        z-index: 3 !important;
        min-width: 300px;
        .list-filter {
            .c-search {
                top: 5px;
            }
            input {
                border: 0;
                width: 90%;
                height: 100%;
                padding: 0;
            } 
        }
        ul {
            padding: 0 !important;
            li {
                border-bottom: 1px solid rgba(221, 221, 221, 0.2);
                display: flex;
                .multiselect-label {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0.3px;
                    text-transform: capitalize;
                    color: #000000 !important;
                }
            }
        }
    }
    .pure-checkbox input[type="checkbox"] + label:before {
        border-radius: 2px;
        color: var(--colorCommon);
        border: 2px solid var(--colorCommon);
    }
    .pure-checkbox input[type="checkbox"]:checked + label:before {
        border-radius: 2px;
        background: var(--colorCommon) !important;
        border: 1px solid var(--colorCommon) !important;
    }
    .pure-checkbox label::before {
        border-radius: 0.25rem !important;
        border: 1px solid #000 !important;
        padding: 1px !important;
    }
    .user-role {
        grid-template-columns: 14rem 1fr 6rem;
    }
}