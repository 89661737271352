@import "@nebular/theme/styles/global/breakpoints";
.lh-0 {
    line-height: 0;
}

.lh-1 {
    line-height: 1;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.cursor-p {
    cursor: pointer;
}

.cursor-d {
    cursor: default;
}

.cursor-na {
    cursor: not-allowed;
}

.opacity-5 {
    opacity: .5;
}

.d-grid {
    display: grid;
}

.hover-underline:hover {
    opacity: .88;
    text-decoration: underline;
}

.outline-none {
    outline: none;
}

.circle-effect {
    padding: .1875rem;
    position: relative;
    &:before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 24px;
        transform: scale(0);
        transition: transform 0.2s;
        background: rgba(255, 255, 255, 0.1);
    }
    &:hover::before {
        transform: scale(1);
    }
}

.commonBtn {
    padding: 5px 15px;
    border-radius: 4px;
    font-weight: 500;
    // font-size: 12px;
    color: var(--colorBtnTxt);
    background: var(--colorBgbtn);
    border: 1px solid var(--colorBgbtn);
    &:hover {
        opacity: .9;
    }
}

.commonBtn-lg {
    outline: none;
    font-weight: 500;
    font-size: .875rem;
    border-radius: .25rem;
    padding: .5rem 1.25rem;
    letter-spacing: .015rem;
    color: var(--colorBtnTxt);
    background: var(--colorBgbtn);
    border: .0625rem solid var(--colorBgbtn);
    &:hover {
        opacity: .9;
    }
}

.commonBtn-outline-lg {
    outline: none;
    font-weight: 500;
    font-size: .875rem;
    border-radius: .25rem;
    padding: .5rem 1.25rem;
    letter-spacing: .015rem;
    color: var(--colorBgbtn);
    background: var(--colorBtnTxt);
    border: .0625rem solid var(--colorBgbtn);
    &:hover {
        opacity: .9;
    }
}

.button {
    outline: none;
    min-width: 8rem;
    text-align: center;
    padding: .6rem 1rem;
    border-radius: .25rem;
    @include media-breakpoint-down(md) {
        min-width: 5rem;
        font-size: .875rem;
    }
}

.button-sm {
    outline: none;
    min-width: 5rem;
    font-size: .875rem;
    padding: .5rem .8rem;
    border-radius: .25rem;
}

.btn-fill {
    color: var(--colorBtnTxt);
    background: var(--colorBgbtn);
    border: .03125 solid var(--colorBtnTxt);
}

.btn-outline {
    color: var(--colorBgbtn);
    background: var(--colorBtnTxt);
    border: .0625rem solid var(--colorBgbtn);
}

.link {
    color: var(--colorCommon);
    text-decoration: underline;
}

.customBtn {
    padding: 5px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: var(--colorCommon);
    font-weight: 500;
    font-size: 12px;
    background: var(--colorBtnTxt);
    border: 1px solid var(--colorBgbtn);
    &:focus {
        outline: none;
    }
    &:active {
        color: var(--colorBtnTxt);
        background: var(--colorCommon);
    }
    &:hover {
        color: var(--colorBtnTxt);
        background: var(--colorCommon);
    }
}

.customBtnWhite {
    padding: 5px 15px;
    border-radius: 4px;
    color: grey;
    font-weight: 500;
    font-size: 12px;
    background: none;
    border: none;
    &:focus {
        outline: none;
    }
    &:active {
        color: grey;
        background: none;
    }
}

.disable-pointer-events * {
    pointer-events: none;
}

.enable-pointer-events * {
    pointer-events: all;
}

.line-wrap {
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.white-space-nowrap  {
    white-space: nowrap;
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
}
.line-clamp-2 {
    -webkit-line-clamp: 2;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

// ==========================================
// Tabs start
// ==========================================
.sub-tabs {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    display: flex;
    list-style-type: none;
    border-bottom: 1px solid #dee2e6;
    li {
        cursor: pointer;
        font-size: 14px;
        color: #3E404D;
        padding: 5px 20px 10px;
        @media (max-width: 576px) {
            font-size: 12px;
        }
    }
    li.active {
        border-bottom: 2px solid var(--colorCommon);
        @media (max-width: 576px) {
            border-width: 1px;
        }
        span {
            color: var(--colorCommon);
        }
    }
}

.cip-tabs {
    top: 56px;
    position: fixed;
    background: #ffffff;
    box-shadow: 0 3px 8px #00000029;
}

// ==========================================
// Tabs end
// ==========================================
// ==========================================
// Checkbox start
// ==========================================
.checkbox {
    line-height: 1;
    cursor: pointer;
    position: relative;
    padding-left: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &--name {
        font-size: .875rem;
    }
    input {
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
    }
    .checkmark {
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        position: absolute;
        border-radius: .1875rem;
        border: .0625rem solid #707070;
        background-color: var(--colorBtnTxt);
        &::after {
            top: .125rem;
            width: .25rem;
            height: .5rem;
            left: .3rem;
            border: solid var(--colorBtnTxt);
            border-width: 0 .125rem .125rem 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &:hover input~.checkmark {
        background-color: #f1f1f1;
    }
    input:checked~.checkmark {
        border-color: var(--colorCommon);
        background-color: var(--colorCommon);
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked~.checkmark:after {
        display: block;
    }
}

// ==========================================
// Checkbox end
// ==========================================
.invalid-msg,
.required_mark {
    color: red;
}
