:root {
  --fc-event-bg-color: transparent,
    --fc-event-border-color: transparent,
}

$primary: var(--colorCommon);
$danger: #f55555;
$success: green;
$gray: #707070;
$body-text: #3e404d;
$warning: #ffc107;
$white: #ffffff;
$black: #000000;

$gray-100: #b8b8b8;

// pastel colors
$pastels: (
  1: #FFF9C4,
  2: #e9ebf9,
  3: #ceffda,
  4: #FFCCBC,
);

// events colors
$events: (
  // 1: #f6a9ff,
  // 2: #b7a9ff,
  // 3: #a9ffbb,
  // 4: #fff1a9,
  // 5: #ffa9b2,
  // 6: #a9ceff,
  // 7: #c4ffa9,
  // 8: #ffb8a9,

  1: #D8BFD8,
  2: #90EE90,
  3: #ADD8E6,
  4: #FFD580,
  5: #D3D3D3,
  6: #D8BFD8,
  7: #90EE90,
  8: #ADD8E6,
);


// $gr2 : (
//   1: #40AAFF,
//   2: #FF923F,
//   3: #78C119,
//   4: #FF409A,
//   5: #9166F9,
//   6: #A888A5,
// );

// $gr1: (
//   1: #3F6DFF,
//   2: #FF5640,
//   3: #16BE2B,
//   4: #FF3F42,
//   5: #853FFF,
//   6: #B80EB2,
// );

$gr22 : (
   #40AAFF,
   #FF923F,
   #78C119,
   #FF409A,
   #9166F9,
   #A888A5,
);

$gr11: (
   #3F6DFF,
   #FF5640,
   #16BE2B,
   #FF3F42,
   #853FFF,
   #B80EB2,
);


// $gradients: (
//   start : (
//     1: #40AAFF,
//     2: #FF923F,
//     3: #78C119,
//     4: #FF409A,
//     5: #9166F9,
//     6: #A888A5,
//   ),
//   end: (
//     1: #3F6DFF,
//     2: #FF5640,
//     3: #16BE2B,
//     4: #FF3F42,
//     5: #853FFF,
//     6: #B80EB2,
//   )
// );

.body-text {
  color: $body-text !important;
}

// ==================================
// TEXT
// ==================================
.text-primary {
  color: $primary !important;
}

.text-danger {
  color: $danger !important;
}

.text-success {
  color: $success !important;
}

.text-warning {
  color: $warning !important;
}

.text-gray {
  color: $gray !important;
}

// ==================================
// BACKGROUND
// ==================================
.bg-primary {
  background: $primary !important;
}

.bg-danger {
  background: $danger !important;
}

.bg-success {
  background: $success !important;
}

.bg-gray {
  background: $gray !important;
}

.bg-gray-100 {
  background: $gray-100 !important;
}

.bg-black {
  background: $black;
}

// ==================================
// SVG FILL
// ==================================
.fill-none {
  fill: none;
}

.fill-white {
  fill: $white !important;
}

.fill-primary {
  fill: $primary !important;
}

.fill-primary-light {
  fill: rgba(var(--commonColorRGB), .2);
}

.fill-success {
  fill: $success !important;
}

.fill-danger {
  fill: $danger !important;
}

.fill-gray {
  fill: $gray !important;
}

// ==================================
// SVG STROKE
// ==================================
.stroke-white {
  stroke: $white !important;
}

.stroke-primary {
  stroke: $primary !important;
}

.stroke-success {
  stroke: $success !important;
}

.stroke-danger {
  stroke: $danger !important;
}

.stroke-gray {
  stroke: $gray !important;
}

.stroke-gray-100 {
  stroke: $gray-100 !important;
}

.stroke-black {
  stroke: $black !important;
}

.stroke-text {
  stroke: $body-text;
}

.stroke-primary {
  stroke: var(--colorCommon);
}

// ==================================

// @each $key, $val in $pastels {
//   .bg-pastel-#{$key} {
//     background: $val;
//   }
//   .border-left-#{$key} {
//     border-left: 3px solid $val;
//   }
// }

@each $key, $val in $events {
  .event-color-#{$key} {
    border: 1px solid $val;
  }

  .event-border-left-#{$key} {
    border-left: 3.5px solid $val;
  }

  .mark-event-#{$key} {
    background: $val;
  }
}



$colors11: (
   #BE0062,
   #D3A108
);

$custom-colors: (
   #20B7CE,
   red,
);

// @for $i from 1 through 6 {
//   @each $key, $val in 6 {
//     .color-test-#{$i} {
//       // background: linear-gradient(45deg, map-get($gr1, $key), map-get($gr2, $key));
//       // background: linear-gradient(45deg, map-values($gr1), map-values($gr2));
//     }
//   }
// }



$i: 0;

// @for $i from 1 through length($colors11) {
  @each $space in $gr11 {
    @each $side in $gr22 {
      $i: $i + 1;

      .color-test-#{$i} { 
        background: linear-gradient(45deg, $space, $side);
      }     
    }
  }
// }



// $gradients: (
//   1:#3F6DFF,#40AAFF,
//   2:#FF5640,#FF923F,
//   3:#16BE2B,#78C119,
//   4:#FF3F42,#FF409A,
//   5:#853FFF,#9166F9,
//   6:#B80EB2,#A888A5,
// );

// @each $i, $key, $value in $gradients {
//   .color-test-#{$i} { 
//     background: linear-gradient(45deg, $key, $value);
//   }    
// }




// $index: 1,2,3,4,5,6;
// $colors: #40AAFF,#FF923F,#78C119,#FF409A,#9166F9,#A888A5;
// $styles: #3F6DFF,#FF5640,#16BE2B,#FF3F42,#853FFF,#B80EB2;
// @include style-matrix($index, $colors, $styles);

// @mixin style-matrix($index, $colors, $styles) {
//   @each $i in $index {
//     @each $s in $styles {
//       @each $c in $colors {
//         .color-test-#{$i} {
//           background: linear-gradient(45deg, $s, $c);
//         }
//       }
//     }
//   }
// }



// @debug color-test-#{$i};

// $pbcolors: (
//   pbcyan : (
//     50: #E5F5FC,
//     100: #CCEBF9,
//     200: #99D7F2,
//   ),
//   pbmediumblue: (
//     50: #E5F1F8,
//     100: #CCE3F1,
//     200: #99C7E3,
//   ),
//   pbpurple: (
//     50: #F5ECF5,
//     100: #ECD9EB,
//     200: #D9B2D7,
//   )
// );

// @each $item, $color in $pbcolors {
//   @each $shade, $value in $color {
//     .bg-#{$item}-#{$shade} {
//       background-color: $value;
//     }
//   }
// }

// @each $item, $color in $gradients {
//   @each $shade, $value in $item {
//     .bg-#{$shade}-#{$value} {
//       background-color: map-get(map-get($gradients, $item), $value);
//     }
//   }
// }