// @nebular theming framework
@import "@nebular/theme/styles/theming";
// @nebular out of the box themes
@import "@nebular/theme/styles/themes";
@import "../../../vendors/fonts/fonts.scss";
@import '@nebular/theme/styles/themes/default';
$nb-themes: nb-register-theme( ( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: #f7f9fc, slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, font-family-primary: "Segoe UI", layout-padding: 0, header-height: 3.75rem, header-shadow: none, header-text-font-size: 0.85rem, header-padding: 0rem, layout-background-color: #ffffff, layout-text-color: #3E404D, layout-text-font-family: "Segoe UI", layout-small-padding: 0, layout-medium-padding: 0, text-caption-font-family: font-family-primary, text-caption-font-size: 0.75rem, text-caption-font-weight: 400, text-caption-line-height: 1rem, spinner-basic-background-color: #ffffff, spinner-primary-background-color: #ffffff, spinner-primary-circle-filled-color: var(--colorCommon), tabset-tab-active-text-color: var(--colorCommon), tabset-tab-active-underline-color: var(--colorCommon), tabset-tab-hover-underline-color: var(--colorCommon), icon-primary-color: var(--colorCommon)), default, default);
$nb-themes: nb-register-theme( ( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: #f7f9fc, slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, font-family-primary: "Segoe UI", layout-padding: 0, header-height: 3.75rem, header-shadow: none, header-text-font-size: 0.85rem, header-padding: 0rem, layout-background-color: #ffffff, layout-text-color: #3E404D, layout-text-font-family: "Segoe UI", layout-small-padding: 0, layout-medium-padding: 0, text-caption-font-family: font-family-primary, text-caption-font-size: 0.75rem, text-caption-font-weight: 400, text-caption-line-height: 1rem, spinner-basic-background-color: #ffffff, spinner-primary-background-color: #ffffff, spinner-primary-circle-filled-color: var(--colorCommon), tabset-tab-active-text-color: var(--colorCommon), tabset-tab-active-underline-color: var(--colorCommon), tabset-tab-hover-underline-color: var(--colorCommon), icon-primary-color: var(--colorCommon)), cosmic, cosmic);
$nb-themes: nb-register-theme( ( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: #f7f9fc, slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, font-family-primary: "Segoe UI", layout-padding: 0, header-height: 3.75rem, header-shadow: none, header-text-font-size: 0.85rem, header-padding: 0rem, layout-background-color: #ffffff, layout-text-color: #3E404D, layout-text-font-family: "Segoe UI", layout-small-padding: 0, layout-medium-padding: 0, text-caption-font-family: font-family-primary, text-caption-font-size: 0.75rem, text-caption-font-weight: 400, text-caption-line-height: 1rem, spinner-basic-background-color: #ffffff, spinner-primary-background-color: #ffffff, spinner-primary-circle-filled-color: var(--colorCommon), tabset-tab-active-text-color: var(--colorCommon), tabset-tab-active-underline-color: var(--colorCommon), tabset-tab-hover-underline-color: var(--colorCommon), icon-primary-color: var(--colorCommon)), corporate, corporate);
$nb-themes: nb-register-theme( ( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: #f7f9fc, slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, font-family-primary: "Segoe UI", layout-padding: 0, header-height: 3.75rem, header-shadow: none, header-text-font-size: 0.85rem, header-padding: 0rem, layout-background-color: #ffffff, layout-text-color: #3E404D, layout-text-font-family: "Segoe UI", layout-small-padding: 0, layout-medium-padding: 0, text-caption-font-family: font-family-primary, text-caption-font-size: 0.75rem, text-caption-font-weight: 400, text-caption-line-height: 1rem, spinner-basic-background-color: #ffffff, spinner-primary-background-color: #ffffff, spinner-primary-circle-filled-color: var(--colorCommon), tabset-tab-active-text-color: var(--colorCommon), tabset-tab-active-underline-color: var(--colorCommon), tabset-tab-hover-underline-color: var(--colorCommon), icon-primary-color: var(--colorCommon)), dark, dark);
//  tabset-tab-active-text-color:var(--colorCommon), tabset-tab-active-text-color:var(--colorCommon), tabset-tab-focus-text-color:var(--colorCommon),tabset-tab-focus-underline-color: var(--colorCommon)
:host {
    background: nb-theme(background-basic-color-1);
}
