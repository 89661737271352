// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
// themes - our custom or/and out of the box themes
@import "themes";
// @import "~ng-pick-datetime/assets/style/picker.min.css";
// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";
// @import "~@nebular/auth/styles/all";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
// loading progress bar theme
@import "./pace.theme";
@import "./layout";
@import "./overrides";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// context menu css
@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';

// install the framework and custom global styles
@include nb-install() {
    // framework global styles
    @include nb-theme-global();
    // @include nb-auth-global();
    @include ngx-layout();
    // loading progress bar
    @include ngx-pace-theme();
    @include nb-overrides();
}

@import "css-star-rating/scss/star-rating";
@import "../../../../node_modules/angular-calendar/css/angular-calendar.css";
@import "./common";
@import "./form-elements";
@import "./variables";
@import "./mixin";

:root {
    --swiper-navigation-size: 1rem;
}

// Intro
.customTooltip {
    background-color: rgba(0, 0, 0, 0.7) !important;
    max-width: fit-content !important;
    .introjs-skipbutton {
        position: absolute;
        bottom: 7.5%;
        text-transform: uppercase;
    }
    a,
    a:hover,
    a:active,
    a:focus,
    a:visited {
        color: inherit;
        text-decoration: none;
        border: none;
    }
    .introjs-prevbutton {
        display: none;
    }
    .introjs-helperNumberLayer {
        padding-top: unset;
        padding-bottom: unset;
    }
    .introjs-tooltipbuttons {
        text-transform: uppercase;
        background: none;
        margin-top: 12px;
        padding: 0.7em 1em;
        // a {
        //     color: inherit;
        //     text-decoration: none;
        // }
    }
    // .introjs-progress   {
    //     height: 5px;
    // }
}

// .owl-item>div {
//     cursor: pointer;
//     margin: 6% 8%;
//     transition: margin 0.4s ease;
// }
// .owl-item.center>div {
//     cursor: auto;
//     margin: 0;
// }
// .owl-item:not(.center)>div:hover {
//     opacity: .75;
// }
