@for $i from 0 through 100 {
    .h-#{$i}-vh {
        height: #{$i}vh;
    }
    .h-#{$i}-rem {
        height: #{$i}rem;
    }
    .h-#{$i}-px {
        height: #{$i}px;
    }
    .h-#{$i} {
        height: #{$i + '%'};
    }
    .w-#{$i}-rem {
        width: #{$i}rem;
    }
    .w-#{$i}-px {
        width: #{$i}px;
    }
    .w-#{$i} {
        width: #{$i + '%'};
    }
    .z-#{$i} {
        z-index: #{$i};
    }
    .line-clamp-#{$i} {
        -webkit-line-clamp: $i;
    }
    .top-#{$i}-px {
        top: #{$i}px;
    }
    .top-n#{$i}-px {
        top: -#{$i}px;
    }
    .top-#{$i}-rem {
        top: #{$i}rem;
    }
    .right-#{$i}-px {
        right: #{$i}px;
    }
    .right-#{$i}-rem {
        right: #{$i}rem;
    }
    .bottom-#{$i}-px {
        bottom: #{$i}px;
    }
    .bottom-#{$i}-rem {
        bottom: #{$i}rem;
    }
    .left-#{$i}-px {
        left: #{$i}px;
    }
    .left-n#{$i}-px {
        left: -#{$i}px;
    }
    .left-#{$i}-rem {
        left: #{$i}rem;
    }
    .text-#{$i}-rem {
        font-size: #{$i}rem;
    }
    .text-#{$i}-px {
        font-size: #{$i}px;
    }
    .border-radius-#{$i}px {
        border-radius: #{$i}px;
    }
}

// @for $i from 1 through 100 {
//     .h-#{$i}vh {
//         height: #{$i}vh;
//     }
// }