@import './themes';
@mixin nb-overrides() {
    nb-select.size-medium button {
        padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
        nb-icon {
            right: 0.41rem !important;
        }
    }
    // h1,
    // h2,
    // h3,
    // h4,
    // h5,
    // h6,
    // p,
    // label,
    // span,
    // li {
    //     color: #3E404D;
    // }

    .ngx-pagination .current {
        border-radius: .125rem;
        background: var(--colorCommon);
    }
    .ngx-pagination a:hover, .ngx-pagination button:hover {
        background: #ffffff;
        color: var(--colorCommon);
    }
    .ngx-pagination a, .ngx-pagination button {
        text-decoration: none;
    }

    .layout {
        overflow-x: hidden;
    }

    nb-spinner.status-primary {
        z-index: 1;
        background-color: #dadada9e;
    }
}

.owl-nav {
    // height: 47px;
    position: absolute;
    width: 100%;
    cursor: pointer;
    // top: 75px !important;
    top: 40% !important;
}

// .owl-carousel .owl-stage-outer {
//   overflow: inherit !important;
// }
owl-carousel-o.smallcard>.owl-carousel>.owl-nav {
    top: 36px !important;
}

.owl-prev {
    position: absolute;
    left: 10px;
    // background: var(--colorCommon);
}

.owl-next {
    position: absolute;
    right: 10px;
    // background: var(--colorCommon);
}

.prev-slide {
    // background: url(nav-icon.png) no-repeat scroll 0 0;
    left: 0;
}

// .owl-nav {
//   // height: 47px;
//   position: absolute;
//   width: 100%;
//   cursor: pointer;
//   top: 75px !important;
// }
// owl-carousel-o.smallcard > .owl-carousel > .owl-nav{
//   top: 36px !important;
// }
// .owl-prev {
//   position: absolute;
//   left: 10px;
// }
// .owl-next {
//   position: absolute;
//   right: 10px;
// }
// .prev-slide {
//   // background: url(nav-icon.png) no-repeat scroll 0 0;
//   left: 0;
// }
// .next-slide {
//   // background: url(nav-icon.png) no-repeat scroll -24px 0px;
//   right: 0;
// }
.progress-bar {
    background-color: var(--colorCommon);
}

.nb-theme-default .cdk-overlay-container {
    z-index: 10400 !important;
}

// .nb-layout-header .fixed{
//   z-index: 10300 !important;
// }

.gauge-chart {
    margin: auto;
}

// =======================================================================

.plyr--full-ui input[type=range]::-moz-range-track {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari, Chrome, Opera, Samsung */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Edge, IE */
    user-select: none; /* Modern browsers */
}

// full calendar color variable
.fc .fc-button-primary {
    text-transform: capitalize;

    --fc-button-bg-color: var(--colorCommon);
    --fc-button-border-color: var(--colorCommon);

    --fc-button-hover-bg-color: #b1afaf;
    --fc-button-hover-border-color: #a2a2a2;
    --fc-button-active-bg-color: #d8d8d8;
    --fc-button-active-border-color: #a2a2a2;
}

.fc .fc-button-active {
    font-weight: 700;
    color: #000000 !important;
    --fc-button-active-bg-color: #d8d8d8;
    --fc-button-active-border-color: #a2a2a2;
}

// ========================================================
// ========================================================

// context menu

.custom-contextmenu {
    --ngx-contextmenu-text-color: #3e404d;
    --ngx-contextmenu-item-padding: 10px 20px;
    --ngx-contextmenu-item-separator-padding: 0;
    --ngx-contextmenu-border: 1px solid #e9ecef;
    --ngx-contextmenu-item-separator-color: #e9ecef;
    --ngx-contextmenu-item-background-hover-color: #f8f8f8;
}

.preferences-contextmenu li {
    font-size: 12px;
}